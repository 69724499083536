import React from 'react';

interface SnefnugProps {
  left: number;
  duration: number;
}

const Snefnug: React.FC<SnefnugProps> = ({ left, duration }) => {
  const style: React.CSSProperties = {
    position: 'absolute',
    left: `${left}%`,
    top: '-10px',
    animation: `snefnug-dale ${duration}s linear infinite`,
  };

  return <div className="snefnug" style={style}></div>;
};

export default Snefnug;
