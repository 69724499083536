import React, { useState, useEffect } from 'react';
import './App.css';
import Snefnug from './Snefnug';
import backgroundImage from './Images/xmas.jpg';

interface SnefnugData {
  left: number;
  duration: number;
}

const App: React.FC = () => {
  const [snefnugList, setSnefnugList] = useState<SnefnugData[]>([]);
  const [lugeStatus, setLugeStatus] = useState<boolean[]>(Array(24).fill(false));

  const handleLukeClick = (index: number) => {
    // Implementer logikken for at håndtere klik på en luke her
    // F.eks. opdater luken til at være åben
    const updatedLukeStatus = [...lugeStatus];
    updatedLukeStatus[index] = true;
    setLugeStatus(updatedLukeStatus);
  };

  useEffect(() => {
    const timer = setInterval(() => {
      const newSnefnug: SnefnugData = {
        left: Math.random() * 100, // Tilfældig placering fra 0% til 100% på x-aksen
        duration: Math.random() * 10 + 5, // Tilfældig varighed fra 5 sekunder til 15 sekunder
      };

      setSnefnugList((prevList) => [...prevList, newSnefnug]);
    }, 1000); // Tilføj et nyt snefnug hvert sekund

    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <div className="app" style={{ backgroundImage: `url(${backgroundImage})` }}>
    {/* <!--div className="app" --> */}
      <h1>Julekalender</h1>
      <div className="luke-container">
        {lugeStatus.map((isOpen, index) => (
          <div
            key={index}
            className={`luke ${isOpen ? 'open' : 'closed'}`}
            onClick={() => handleLukeClick(index)}
          >
            {index + 1}
          </div>
        ))}
      </div>
      {snefnugList.map((snefnug, index) => (
        <Snefnug key={index} left={snefnug.left} duration={snefnug.duration} />
      ))}
    </div>
  );
};

export default App;
